import React from "react";
import YoutubeEmbed from "./YoutubeEmbed";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function WeekSummary() {
  return (
    <>
    {/* <span className="italic bold">dominant</span> */}
    {/* <span className="bold">dominant</span> */}
      <div className="section section-weekly-summary">
        <Container>
          <Row>
            <Col md="12">
            <p className="category">

              <YoutubeEmbed embedId="yiFNrO33bSo"></YoutubeEmbed><br></br>

              First <span className="bold green">Congrats are in order</span> for the undefeated teams of RML:  <span className="bold blue">Double Trouble, Bama Blackout and The Big D</span>!!!
<br></br><br></br>
              Boy, it makes you look back at the draft like “<span className="italic bold">Shit, I knew I should have got (player) punk ass!</span>“ These new
              players are <span className="bold orange">something else boy</span>! Everybody wants to be on <span className="bold green">vegan diets</span> then lose all their muscle and
              can’t figure out why they are hurt all the time! Some players just don’t know when to slide and get <span className="bold red">head trauma</span>, 
              so they get the <span className="bold blue">Toad helmets</span> or out completely! Or you got the “<span className="italic bold">Give me my money</span>” gang who
              won’t play the preseason cause they don’t want to get hurt, then get hurt anyway or are just rusty AF! As
              you can see, I am highly annoyed and all the above just “<span className="italic bold">Burns My Grits</span>”! <span className="bold green">You pick where your fallin solider lies</span>……

              

            {/* Congrats on what's gotta be the most <span className="bold green">entertaining</span>, <span className="bold blue">competitive</span> season in the history of the <span className="bold">League</span>. To the teams that didn't make the playoffs, we thank you for your <span className="bold orange">grit and determination</span>. <br/><br/>
            <div className="text-center"> On the AFC's side, we got two teams:</div>
            <br/>
            <span className="bold">BAMA BLACKOUT:</span> He's been leading the pack all year after starting out 8-0. Can he break through for the first time in 6 years?<br/><br/> */}
            
            </p>
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/devin-white.png").default}
                ></img>
              </div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default WeekSummary;
