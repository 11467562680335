/*eslint-disable*/
import React from "react";
import { Link } from "react-router-dom";

// reactstrap components
import {
    Container,
    Row,
    Col
  } from "reactstrap";

// core components

function Trophy(props) {
    const teams = props.teams.map((team) => {
        return <h3 className="title ml-auto mr-auto text-center col-md-8 trophy-font" style={{marginBottom: "0"}}>{team}</h3>
    }) 
    console.log("TEAMS: " + props.teams)
    return (
      <>
        {
            teams != "" ? 
            (
                <>
                    <Row>
                        <img
                            className="ml-auto mr-auto text-center col-md-8"
                            alt="..."
                            src={require("assets/img/" + props.trophyName + ".png").default}
                            style={{
                                maxWidth: "60%",
                                marginTop: props.first ? "270px" : "100px"
                            }}                
                        ></img>  
                        </Row>
                        <br/>
                        <Row>            
                        {teams}
                    </Row>
                </>
            ) : 
            (
                <></>
            )}
      </>
    );
}


export default Trophy;
