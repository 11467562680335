import React from "react";

// reactstrap components
import { Container, Row, Col } from "reactstrap";

// core components

function Images() {

  // let pageImages = React.createRef();

  // React.useEffect(() => {
  //   if (window.innerWidth > 991) {
  //     const updateScroll = () => {
  //       let windowScrollTop = window.pageYOffset / 3;
  //       pageImages.current.style.transform =
  //         "translate3d(0," + windowScrollTop + "px,0)";
  //     };
  //     window.addEventListener("scroll", updateScroll);
  //     return function cleanup() {
  //       window.removeEventListener("scroll", updateScroll);
  //     };
  //   }
  // });

  return (
    <>
      <div className="section section-images">
        <Container>
          <Row>
            <Col md="12">
              {/* <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-1.png").default}
                ></img>
              </div> */}
              <div className="hero-images-container">
                <img
                  alt="..."
                  src={require("assets/img/parsons.png").default}
                ></img>
              </div>
              {/* <div
                className="hero-images-container"
                style={{
                  backgroundImage:
                    "url(" + require("assets/img/devin-white.png").default + ")",
                }}
                ref={pageImages}
              ></div> */}
              {/* <div className="hero-images-container-1">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-2.png").default}
                ></img>
              </div>
              <div className="hero-images-container-2">
                <img
                  alt="..."
                  src={require("assets/img/hero-image-3.png").default}
                ></img>
              </div> */}
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Images;
