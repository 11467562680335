import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import { Button, Container, Row } from "reactstrap";
import PowerRanking from "./PowerRanking";

// core components

function PowerRankings() {
    const powerRankings = [
      { index: "2", previous: "3", team: "bama" },  
      { index: "8", previous: "6", team: "zeke" },
      { index: "12", previous: "11", team: "easy" },
      { index: "10", previous: "9", team: "kingz" },
      { index: "5", previous: "7", team: "juice" },
      { index: "7", previous: "5", team: "goonz" },
      { index: "6", previous: "8", team: "tds" },
      { index: "1", previous: "2", team: "double" },
      { index: "11", previous: "10", team: "calvary" },
      { index: "9", previous: "12", team: "zo" },
      { index: "3", previous: "4", team: "d" },
      { index: "4", previous: "1", team: "dandy" },
    ];

    const sortedRankings = powerRankings.sort((a, b) => parseInt(a.index) - parseInt(b.index));

  return (      
    <>
      <div className="section section-power-rankings fade-top" data-background-color="extra-dark">
        <Container className="text-center">
        <br/>
        <br/>
        <br/>
        <br/>
        <table class="table">
            <tbody>
                <tr>
                    <td></td>                
                    <td>
                    <img
                                alt="..."
                                src={require("assets/img/PowerRankings.png").default}
                                style={{                                
                                }}                
                            ></img>
                    </td>
                    <td></td>
                </tr>
                {
                    sortedRankings.map(ranking => {
                        return <PowerRanking index={ranking.index} previous={ranking.previous} team={ranking.team}></PowerRanking>
                    })
                }                              
            </tbody>
        </table>
        </Container>
      </div>
      <br/>
      <br/>
    </>
  );
}

export default PowerRankings;
