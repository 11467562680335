/*eslint-disable*/
import React from "react";

// reactstrap components
import { Container } from "reactstrap";

function DarkFooter() {
  return (
    <footer className="footer" data-background-color="extra-dark">
      <Container>        
        <div className="copyright" id="copyright">
          © {new Date().getFullYear()}, Designed and Developed By Leaguacy Sports LLC
        </div>
      </Container>
    </footer>
  );
}

export default DarkFooter;
