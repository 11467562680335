/*eslint-disable*/
import React from "react";
import Trophy from "./Trophy";

import {
    Container
  } from "reactstrap";

// core components

function WeeklyPayouts() {
    
    return (
      <>    
      <div className="clear-filter">  
        <div
          className="section section-trophies"
          data-background-color="extra-dark"
          style={{
            backgroundImage:
              "url(" + require("assets/img/water-cooler.png").default + ")",
            backgroundSize: "contain",
            backgroundPosition: "top center",
            backgroundRepeat: "no-repeat",
            minHeight: "1150px",
          }}
        >
        <br/>
        <br/>
        </div>
        </div>
      </>
    );
}


export default WeeklyPayouts;
