import React from "react";

function PowerRanking(props) {
  return (
    <>
      <tr>
            <td>
                <img
                        alt="..."
                        src={require("assets/img/chalk-" + props.index +".png").default}
                        style={{                                
                        }}                
                    ></img>
            </td>                    
            <td>
                <img
                        alt="..."
                        src={require("assets/img/" + props.team + ".png").default}
                        style={{                                
                        }}                
                    ></img>
            </td>
            <td>
            {
                props.movement != "" ? 
                (
                    <>
                        <img
                        alt="..."
                        src={require("assets/img/" + getDifference(parseInt(props.previous) - parseInt(props.index)) + ".png").default}
                        style={{                                
                        }}                
                    ></img>
                    </>
                ) : 
                (
                    <></>
                )}                
            </td>
        </tr>        
    </>
  );

  function getDifference(difference)
  {
    switch(difference) {
        case -9:
            return "minus-9";
        case -8:
            return "minus-8";
        case -7:
            return "minus-7";
        case -6:
            return "minus-6";
        case -5:
            return "minus-5";
        case -4:
            return "minus-4";
        case -3:
            return "minus-3";
        case -2:
            return "minus-2";
        case -1:
            return "minus-1";
        case 0:
            return "zero";
        case 1:
            return "plus-1";
        case 2:
            return "plus-2";
        case 3:
            return "plus-3";
        case 4:
            return "plus-4";
        case 5:
            return "plus-5";
        case 6:
            return "plus-6";
        case 7:
            return "plus-7";
        case 8:
            return "plus-8";
        case 9:
            return "plus-9";
        default:
            return "";
    }
  }
}

export default PowerRanking;
