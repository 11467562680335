import React from "react";
import Matchup from "./Matchup";
// reactstrap components
import{
  Card,
  CardBody,
  CardTitle,
  CardText,
  Container, 
  Row
} from "reactstrap";

// core components

function Matchups() {
    // const matchups = [
    //     {"winner": "Double Trouble", "loser": "Bama BlackOut", 
    //         "text": "This one was a high scoring affair, with the two highest scoring teams this week squaring off. It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!"},

    //     {"winner": "! Zo", "loser": "Dandy MEN", 
    //         "text": "Deshaun Watson and Will Fuller set the tone early on Thanksgiving day, and <span className='bold team-name-tag'>Dandy MEN</span> never let up, handing <span className='bold team-name-tag'>! Zo</span> his third straight loss. This loss all but eliminates ! Zo from the playoffs. Dandy can clinch his first ever playoff birth with a win next week against Bama. I'm not gonna lie, I'm rooting for the boy Dandy, even though it's me (Bama) he's playing next week!!!!!"},

    //     {"winner": "The Goonz", "loser": "THE Big D", 
    //         "text": "Dominant. <span className='bold team-name-tag'>The Goonz</span> making it look easy once again to get to the magical <i>SEVENTH</i> win this week putting an exclamation point on an impressive season knocking off <span className='bold team-name-tag'>The Big D</span>. D has really struggled this year following an impressive draft, but has vowed to have studied The League like a playbook and knows what all he did wrong in 2020. Their won't be no easy outs in 2021!!!!!"},


    // ];
  return (
    <>
      <div className="section section-weekly-recap">
        <Container>
            <br />
            <br />
            <br />

            {/* {matchups.map(function(matchup, idx){
                 return (<Matchup key={idx} winner={matchup.winner} loser={matchup.loser} text={matchup.text} />)
             })} */}
        {/* <Matchup winner="Double Trouble" loser="Bama BlackOut" 
        text="This one was a high scoring affair, with the two highest scoring teams this week squaring off. 
        It's a shame someone had to lose. Today, <span className='bold team-name-tag'>Double Trouble</span> showed up and showed out snapping 
        the six game winning streak of the <span className='bold team-name-tag'>Bama BlackOut</span>. DUB was sitting at the bottom of the 
        league at 3-8 and showed why he's considered one of the best in RML with this win!!!!!" /> */}
        <Row>
        <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>         
                <span className="bold team-name-tag">Dandy MEN</span> came over to the <span className="bold red">dice game</span> to get his<span className="bold green"> bike back</span> from <span className="bold team-name-tag">Bama BlackOut</span>! <span className="italic bold">Dandy</span> said it was like
both of theirs until <span className="italic bold">Bama</span>’s Murray upper cut Dandy into orbit! Bama asked <span className="bold blue">Lamar Jackson</span> if he wants some
too…<span className="bold red">he said NO!</span>!!!!!
                </CardText>          
                </CardBody>
            </Card>   
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Bama BlackOut</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">Dandy MEN</CardTitle>          
                </CardBody>
            </Card>            
          </Row>
            <br/>
            <br/>
          <Row>
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">TOO Easy</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">LET ME SEE YOUR TDs</CardTitle>          
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>       
                <span className="bold team-name-tag">LET ME SEE YOUR TDs</span> told <span className="bold team-name-tag">TOO Easy</span> if he ain’t have his money by <span className="bold orange">10:00</span> he was <span className="bold red">killing his squad</span>. <span className="italic bold">TDs</span> wasn’t
playing as <span className="bold italic">Kamara and Smith</span> <span className="bold green">took shots</span> at <span className="italic bold">Too Easy</span> all over the neighborhood! I think Too Easy gonna
<span className="bold blue"> pay him</span> next time!!!!!!
                </CardText>          
                </CardBody>
            </Card> 
          </Row>
            <br/>
            <br/>
          <Row>            
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>         
                <span className="bold team-name-tag">Sport of Kingz</span> kept <span className="bold red">kicking over</span> <span className="bold team-name-tag">Double Trouble</span>’s trash cans until <span className="italic bold">DUB</span> used <span className="bold orange">Cousins and The Bears D</span> to
put “<span className="italic bold">Belt to Ass</span>” on S.O.K! He went home <span className="bold blue">crying to him mama</span>!!!!!!
                </CardText>          
                </CardBody>
            </Card>     
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Double Trouble</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">Sport of Kingz</CardTitle>          
                </CardBody>
            </Card> 
          </Row>
          <br/>
            <br/>
          <Row>       
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">King Jaffe Joffer</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">! Zo</CardTitle>          
                </CardBody>
            </Card>        
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>         
                <span className="bold team-name-tag">King Jaffe Joffer</span> tried to keep up with <span className="bold team-name-tag">! Zo</span> and his smokin but <span className="bold green">Purdy and Rodgers</span> were “<span className="italic bold">Angel dust</span>”
something <span className="italic bold">KJC</span> <span className="bold red">couldn’t handle</span>! He still running <span className="bold orange">down the street</span> in his <span className="bold blue">underwear</span>!!!!!!
                
                {/* <img
                  alt="..."
                  src={require("assets/img/broke-make-it-rain.gif").default}
                ></img> */}
              
                </CardText>          
                </CardBody>
            </Card>               
          </Row>
          <br/>
          <br/>
          <Row>
          <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>        
                <span className="bold team-name-tag">The Goonz</span> got <span className="bold red">fired on their day off</span> for <span className="bold green">stealing boxes</span> from <span className="bold team-name-tag">Invisible Juice</span>! <span className="italic bold">Juice</span> said he 
                <span className="bold orange"> had him on camera</span> and proceeded to use <span className="bold blue">Achane and Mason</span> as witnesses!!!!!!
                </CardText>          
                </CardBody>
            </Card>                  
            <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="winner-tag">Invisible Juice</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="loser-tag">The Goonz</CardTitle>          
                </CardBody>
            </Card>
          </Row>
          <br/>
          <br/>
          <Row>            
          <Card style={{ width: "20rem" }}>
                <CardBody className="text-center">
                <CardTitle tag="h3" className="loser-tag">Zeke's Supreme Team</CardTitle>
                <h1>v.</h1>
                <CardTitle tag="h3" className="winner-tag">The Big D</CardTitle>
                </CardBody>
            </Card>
            <Card className="text-center" style={{ width: "50rem" }}>
                <CardBody>
                {/* <CardTitle tag="h4">Special title treatment</CardTitle> */}
                <CardText>        
                <span className="bold team-name-tag">Zeke's Supreme Team</span> decided to just “<span className="italic bold">tuck his chain in</span>” as <span className="bold team-name-tag">The Big D</span> <span className="bold red">rolled up on his bike</span>! <span className="italic bold">Zeke </span> 
                <span className="bold orange">ain’t have nothing on his 40</span>, so <span className="italic bold">The Big D</span> along with <span className="bold green">Hurts and Warner</span> took his chain! <span className="italic bold">Zeke</span> ain’t have
enough muscle to<span className="bold blue"> get his chain back</span> so he had to cry in the car!!!!!
                </CardText>          
                </CardBody>
            </Card>      
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Matchups;
