import React from "react";
import { Link } from "react-router-dom";
// reactstrap components
import {
  Col,
  Container,
  Row,
} from "reactstrap";

// core components

function PlayerOfTheWeek() {
  return (
    <>   
    
       <Container>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title"><span className="team-name-tag" style={{color: "red", padding: "10px", borderRadius: "10px"}}>OFFENSIVE</span> PLAYER OF THE WEEK</h2>                  
            </Col>                                 
          </Row>
        </Container>  

        <Row>
          <Col className="ml-auto mr-auto text-center" md="6">
          <div
                className="section"
                style={{
                backgroundImage:
                    "url(" + require("assets/img/week-off-2022.png").default + ")",
                backgroundSize: "cover",
                // backgroundPosition: "top center",
                minHeight: "780px",
                marginBottom: "0"
                }}
            >   
            </div>
            </Col>  
            <Col className="ml-auto mr-auto text-center" style={{marginTop: "183px"}} md="6">
                    <h3 className="title" style={{marginBottom: "0"}}>Alvin Kamara</h3>
                    <h1 className="title bold">90.5 POINTS</h1>
                    <h3 className="description">
                        LET ME SEE YOUR TDS
                    </h3>
            </Col>            
          </Row>

          <Container style={{marginTop: "-160px"}}>
          <Row>
            <Col className="ml-auto mr-auto text-center" md="8">
                  <h2 className="title"><span className="warning-tag" style={{padding: "10px", borderRadius: "10px"}}>DEFENSIVE</span> PLAYER OF THE WEEK</h2>                  
            </Col>                                 
          </Row>
        </Container>  

        <Row>
        <Col className="ml-auto mr-auto text-center" style={{marginTop: "183px"}} md="6">
                    <h3 className="title" style={{marginBottom: "0"}}>Fred Warner</h3>
                    <h1 className="title bold">52 POINTS</h1>
                    <h3 className="description">
                      THE BIG D
                    </h3>
            </Col>   
          <Col className="ml-auto mr-auto text-center" md="6">
          <div
                className="section section-signup"
                style={{
                backgroundImage:
                    "url(" + require("assets/img/week-def-2022.png").default + ")",
                backgroundSize: "cover",
                // backgroundPosition: "top center",
                minHeight: "780px",
                }}
            >   
            </div>
            </Col>                       
          </Row>
    </>
  );
}

export default PlayerOfTheWeek;
